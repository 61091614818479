.close{
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer;
}
.popup{
    background: rgba(34, 34, 34, 0.40);
    display: flex;
    position: fixed;
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    overflow: hidden;
    z-index: 20;
    flex-direction: column;
    align-items: center;
}
.popupBlock{
    color: var(--tg-theme-text-color);
    background: var(--tg-theme-bg-color);
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    border-radius: 12px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    justify-content: space-between;
}
.popupBlock2{
    gap: 16px;
    display: flex;
    flex-direction: column;
}
.popupText1{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: var(--tg-theme-text-color);
}
.block{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.blockPrize{
    display: flex;
    border-radius: 8px;
    width: 100%;
    border-top: 0.5px solid #D9D9D9;
    border-right: 0.5px solid #D9D9D9;
    border-bottom: 0.5px solid #D9D9D9;
}
.blockPrizeLeft{
    height: 100%;
    width: 8px;
    border-radius: 8px 0 0 8px;
}
.blockPrizeRight{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
}
.imageBlock{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.image{
    width: 100%;
}
.name{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.hashrate{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-hint-color)
}
.hashrate2{
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-text-color);
    white-space: nowrap;
}
.sale{
    border-radius: 16px;
    background: #F6CA46;
    display: flex;
    padding: 0 6px;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
    white-space: nowrap;
}
.blockPrizeRightBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.blockPrizeRightBlock2{
    width: 100%;
}
.text2{
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: var(--tg-theme-hint-color);
}
.send{
    display: flex;
    gap: 6px;
    width: calc(100% - 24px);
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    color: var(--tg-theme-link-color);
    background: #3B86C614;
    padding: 12px;
    cursor: pointer;
}