._{
    position: fixed;
    height: 100%;
    width: 100%;
    color: var(--tg-theme-text-color);
    overflow: auto;

}
.block{
    display: flex;
    background: var(--tg-theme-bg-color);
    margin-top: 12px;
    padding: 12px;
    gap: 12px;
}
.blockInfo{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.imageBlock{
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.image{
    width: 100%;
}
.name{
    text-align: center;

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    margin: 4px 0;
}
.price{
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.price2{
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-hint-color)
}
.hashrate{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-hint-color)
}
.hashrate2{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-text-color);
}
.buttonBlock{
    display: flex;
    gap: 9px;
    margin-top: 8px;
}
.delete{
    border-radius: 6px;
    border: 1px solid #E7E7E7;
    cursor: pointer;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.countBlock{
    color: var(--tg-theme-text-color);
    display: flex;
    border-radius: 6px;
    border: 1px solid var(--tg-theme-text-color);
    padding: 0 6px;
    width: 90px;
    height: 34px;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
}
.minus{
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-left: 2px
}
.plus{
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-right: 2px
}
.inputNumber{
    border:none;
    background: none;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    color: var(--tg-theme-text-color);
    width: 40px;
    text-align: center;
}
.prizeLabel{
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 16px;
    background: #F6CA46;
    position: absolute;
    top: 4px;
    left: 4px;
    padding: 0 4px;
}
.price_2{
    color: var(--tg-theme-hint-color);
    opacity: .7;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-left: 6px;
    text-decoration: line-through;
}
.price2_2{
    color: var(--tg-theme-hint-color);
    opacity: .7;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-left: 6px;
    text-decoration: line-through;
}