.mainBlock{
    height: calc(100vh - 66px);
    overflow-x: auto;
}
.footer{
    height: 66px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s ease;
}
.footer2{
    height: 66px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    background: #E9E8E8;
    color: #BABABA;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    cursor: pointer;
    transition: all .3s ease;
}
.text1{
    color: var(--tg-theme-text-color);;
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.48px;
    margin: 24px 10px;
}
.blockEl{
    background: var(--tg-theme-bg-color);
    padding: 16px 20px;
    margin-bottom: 12px;
    position: relative;
}
.text2{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-text-color);
}
.blockEl2{
    display: flex;
    align-items: flex-end;
}
.inputEl{
    color: var(--tg-theme-text-color);
    background: none;
    border: none;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 30px;
    height: min-content;
    min-width: 30px;
    margin: 0;
    padding: 0;
    font-family: monospace
}
.inputEl::placeholder{
    color: var(--tg-theme-text-color);
}
.text3{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--tg-theme-hint-color);
    margin-bottom: 4px;
}
.loading{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: var(--tg-theme-secondary-bg-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loadingIco{
    border-radius: 12px;
    background: rgba(68, 151, 246, 0.12);
    display: flex;
    padding: 12px;
}
.loadingIco2{
    border-radius: 12px;
    background: rgba(239, 68, 68, 0.12);
    display: flex;
    padding: 12px;
}
.loadingText1{
    color: var(--tg-theme-text-color);;
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
    width: 80%;
    margin: 24px 0 5px 0;
}
.loadingText2{
    color: var(--tg-theme-hint-color);
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: -0.32px;
    width: 80%;
}
.loadingSVG{
    animation: moveCircle 3s ease-in-out infinite;
    transform: translate(10px, 10px);
}

@keyframes moveCircle {
    0% {
        transform: translate(10px, 10px);
    }
    25% {
        transform: translate(10px, -10px);
    }
    50% {
        transform: translate(-10px, -10px);
    }
    75% {
        transform: translate(-10px, 10px);
    }
    100% {
        transform: translate(10px, 10px);
    }
}
.loadingBlock{
    height: calc(100vh - 66px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.price{
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.price2{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-hint-color)
}
.name{
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    display: flex;
    align-items: center;
    gap: 5px;
}
.hashName{
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-hint-color);
    display: flex;
    align-items: center;
    gap: 5px;
}
.hash{
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    display: flex;
    align-items: center;
    gap: 5px;
}
.asicBlock{
    display: flex;
    gap: 12px;
    padding: 12px 20px;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
}
.asicImageBlock{
    width: 57px;
}
.asicImage{
    width: 100%;
    height: auto;
}
.asics{
    background: var(--tg-theme-bg-color);
    margin-bottom: 12px;
}
.allPriceBlock{
    display: flex;
    justify-content: space-between;
    padding: 16px 20px;
}
.allPriceText1{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.allPriceBlock2{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.text4{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 141.176% */
    letter-spacing: -0.34px;
}
.border{
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
}
.send{
    color: var(--tg-theme-link-color);
    background: var(--tg-theme-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    letter-spacing: -0.34px;
    padding: 12px 0;
    cursor: pointer;
}
.popupSendFon{
    position: fixed;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background: rgba(22,22,22,.4);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}
.popupSend{
    padding: 24px;
    background: var(--tg-theme-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
}
.popupSendText{
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    color: var(--tg-theme-text-color);
    opacity: .9;
}
.popupSendInput{
    display: flex;
    padding: 4px 4px 4px 16px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--tg-theme-secondary-bg-color);
    line-height: 22px;
    margin-bottom: 12px;
    height: 36px;
}
.popupSendInputText{
    overflow: hidden;
    color: var(--tg-theme-text-color);
    font-feature-settings: 'calt' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    background: var(--tg-theme-bg-color);
    padding: 0;
    width: 100%;
    border: none;
}
.popupSendCopy{
    display: flex;
    width: 36px;
    min-width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--tg-theme-link-color);
    cursor: pointer;
    position: relative;
}
.popupSendCopyText{
    border-radius: 6px;
    background: var(--tg-theme-link-color);
    display: flex;
    padding: 4px 12px 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    position: absolute;
    top: -40px;
}
.blockProfitSend2{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
    text-transform: uppercase;
    background: var(--tg-theme-link-color);
    border-radius: 6px;
    width: calc(100% - 32px);
    cursor: pointer;
}
.blockProfitSendEdit{
    display: flex;
    justify-content: space-evenly;
}
.emptyBlock{
    height: 1px;
    background: var(--tg-theme-hint-color);
    margin: 24px 0;
    opacity: .5;
}
.blockFreeEl{
    display: flex;
    gap: 12px;
    margin: 20px;
    align-items: center;
}
.popupTopText{
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: -2%;
    text-align: center;
    margin: 10px;
    color: var(--tg-theme-text-color);
}
.popupTopText2{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -2%;
    text-align: center;
    color: var(--tg-theme-text-color);
    margin: 0 10px 20px 10px;
}
.popupTopBottom{
    display: flex;
    color: #FFFFFF;
    background: black;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    gap: 8px;
    width: 100%;
    cursor: pointer;
}
.blockPress{
    position: absolute;
    height: 100%;
    width: 30%;
    left: 0;
    top: 0;
}