._{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: fixed;
    height: calc(100% - 48px);
    width: calc(100% - 32px);
}
.block{
    border-radius: 8px;
    width: 100%;
    height: 224px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    position: relative;
}
.block_d{
    background: #1C1C1C;
}
.block2{
    height: 40%;
    position: relative;
    background: linear-gradient(180deg, rgba(65, 67, 79, 0.00) 0%, rgba(65, 67, 79, 0.12) 100%);
    border-radius: 0 0 8px 8px;
}
.text{
    color: var(--tg-theme-text-color);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 39.2px */
    margin-top: 24px;
    margin-left: 24px;
    z-index: 2;
}
.gif1{
    width: 80%;
    position: absolute;
    bottom: 0;
    right: 0;
}
.prizeBlock{
    border-radius: 8px;
    box-shadow: 0 -4px 16px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    width: calc(100% - 32px);
    padding: 8px 16px;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
}
.prizeBlock1{
    display: flex;
    align-items: center;
    gap: 8px;
}
.prizeImgBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 100%;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    animation: glow 1.3s infinite alternate ease-in-out;
}
@keyframes glow {
    0% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    }
    50% {
        box-shadow: 0 0 25px rgba(255, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    }
}
.prizeImg{
    border-radius: 100%;
    background: #FFF;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.prizeBlockTextBlock{

}
.prizeBlockText1{
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.prizeBlockText2{
    color: #FFF;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.64;
}
.prizeCountBlock{
    display: flex;
    gap: 6px;
}
.prizeCount{
    display: flex;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.12);
    padding: 2px 8px;
    align-items: center;
    gap: 4px;
}
.prizeStr{
    margin: 3px 0 0 0;
    cursor: pointer;
}