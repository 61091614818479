._{
    padding: 40px 16px;
}
.text1{
    color: var(--tg-theme-text-color);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 33.6px */
    margin-bottom: 40px;
}
.block_text{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    width: 100%;
}
.block{
    display: flex;
    padding: 9px 14px 9px 16px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    justify-content: space-between;
}
.block_1{
    border-top: 1px solid var(--tg-theme-secondary-bg-color);
}
.blockModels{
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    margin-bottom: 40px;
}
.imageModelsBlock{
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageModels{
    width: 100%;
}
.searchBlock{
    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 8px;
    background: var(--tg-theme-bg-color);
    padding: 16px 14px 16px 16px;
    cursor: pointer;
}
.searchBlock2{
    display: flex;
    align-items: center;
    gap: 12px;
}
.searchIcon{
    min-width: 32px;
}
.searchIcon2{
    min-width: 6px;
}
.searchText1{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.34px;
}
.searchText2{
    color: var(--tg-theme-hint-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
}
.emptyBlock{
    height: 1px;
    background: var(--tg-theme-hint-color);
    margin: 24px 0;
    opacity: .5;
}