._{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 70px;
}
.block{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
}
.imageBlock{
    width: 124px;
    height: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
}
.image{
    width: 100%;
}
.name{
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-text-color);
}
.price{
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.price2{
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-hint-color)
}
.button{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    border-radius: 6px;
    background: #ED6F35;
    display: flex;
    padding: 6px 16px;
    align-items: center;
    width: 128px;
    margin-top: 12px;
    cursor: pointer;
    justify-content: center;
}
.button2{
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    border-radius: 6px;
    background: #ED6F35;
    display: flex;
    padding: 6px 16px;
    align-items: center;
    width: 128px;
    margin-top: 12px;
    justify-content: space-between;
}
.buttonIcon{
    display: flex;
    cursor: pointer;
}
.inputNumber{
    border:none;
    background: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    color: #FFF;
    width: 60px;
    height: 20px;
    text-align: center;
}
.tags{
    display: flex;
    gap: 4px;
    margin-bottom: 8px;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
}
.tag{
    color:#FFFFFF;
    padding: 0px 4px 2px 4px;
    border-radius: 4px;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.26px;
}
.popupFon{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(34, 34, 34, 0.40);
}
.popup{
    display: flex;
    width: 80%;
    padding: 24px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    background: var(--tg-theme-secondary-bg-color);
}
.popupBlock{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.popupText1{
    color: var(--tg-theme-text-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    letter-spacing: -0.3px;
}
.popupText2{
    color: var(--tg-theme-text-color);
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 147.368% */
    letter-spacing: -0.38px;
}