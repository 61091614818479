.popup{
    background: rgba(34, 34, 34, 0.40);
    display: flex;
    position: fixed;
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    overflow: hidden;
    z-index: 20;
}
.popupBlock{
    color: var(--tg-theme-text-color);
    background: var(--tg-theme-bg-color);
    padding: 24px;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    border-radius: 12px;
    gap: 16px;
    display: flex;
    flex-direction: column;
}
.popupText1{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
    text-align: center;
    color: var(--tg-theme-text-color);
}
.popupText2{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
    display: flex;
    flex-direction: column;
    color: var(--tg-theme-text-color);
    overflow: auto;
}
.popupText2_1{
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
    color: var(--tg-theme-text-color);
}
.popupText2_2{
    padding-left: 12px;
    margin-top: 0;
}
.close{
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer;
}