.text1{

    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    margin-left: 12px;
    padding: 12px 0 12px 0;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
}
.mainBlock{
    height: calc(100vh - 66px);
    overflow: auto;
}
.block1{
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    margin: 12px;
}
.block2{
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    margin: 0 12px 12px 12px;
}
.itemsBlock{
    display: flex;
    gap: 16px;
    padding: 12px 14px 12px 16px;
    flex-direction: column;
    justify-content: center;
}
.totalBlock{
    display: flex;
    margin-left: 12px;
    padding: 12px 12px 12px 0;
    border-top: 1px solid var(--tg-theme-secondary-bg-color);
    justify-content: space-between;
}
.block{
    display: flex;
    justify-content: space-between;
}
.blockLeft{
    display: flex;
}
.imageBlock{
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}
.image{
    width: 100%;
}
.name{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    text-align: left;
}
.price{
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
    white-space: nowrap;
}
.price2{
    text-align: right;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    color: var(--tg-theme-hint-color);
    white-space: nowrap;
}
.price3{
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 141.176% */
    color: var(--tg-theme-text-color);
}
.hashrate{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-hint-color)
}
.hashrate2{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    color: var(--tg-theme-text-color);
}
.block2_1{
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
    display: flex;
    justify-content: space-between;
    margin-left: 12px;
    padding: 12px 12px 12px 0;
    align-items: center;
    cursor: pointer;
}
.text2{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    color: var(--tg-theme-text-color);
    justify-content: space-between;
    width: 100%;
    margin-right: 8px;
    display: flex;
}
.footer{
    padding: 13px 16px;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    display: flex;
    width: calc(100% - 32px);
    background: var(--tg-theme-bg-color);
}
.cancel{
    color: var(--tg-theme-link-color);
    padding: 9px 19px;
    cursor: pointer;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 146.667% */
}
.pay{
    border-radius: 4px;
    background: #E9E8E8;
    display: flex;
    padding: 9px 19px;
    justify-content: flex-end;
    align-items: center;
    color: #BABABA;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 146.667% */
    cursor: pointer;
}
.pay2{
    border-radius: 4px;
    background: var(--tg-theme-button-color);
    display: flex;
    padding: 9px 19px;
    justify-content: flex-end;
    align-items: center;
    color: var(--tg-theme-button-text-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 146.667% */
    cursor: pointer;
}
.popup{
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--tg-theme-secondary-bg-color);
    top: 0;
}
.footer2{
    height: 66px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    cursor: pointer;
}
.text3{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    color: var(--tg-theme-hint-color);
    margin: 24px 0 0 32px;
}
.paymentMethods{
    border-radius: 8px;
    background: var(--tg-theme-bg-color);
    padding: 0 0 0 16px;
    margin: 4px 16px 16px 16px;
}
.text4{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    color: var(--tg-theme-text-color);
}
.paymentMethod{
    display: flex;
    gap: 12px;
    padding: 12px 12px 12px 0;
    border-bottom: 1px solid var(--tg-theme-secondary-bg-color);
    align-items: center;
    justify-content: space-between;
}
.radio{
    width: 20px;
    height: 20px;
}
.text5{
    color: var(--tg-theme-hint-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    margin-left: 5px;
}
.input{
    background: var(--tg-theme-bg-color);
    width: calc(100% - 90px);
    border: none;
    color: var(--tg-theme-text-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
}
.textarea{
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 137.5% */
    border: none;
    margin: 4px 16px 16px 16px;
    padding: 16px;
    border-radius: 8px;
    width: calc(100% - 64px);
    height: 88px;
}
.mainBlock2{
    height: calc(100vh - 66px);
    overflow: auto;
    display: flex;
    padding: 0 16px;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.text6{
    color: var(--tg-theme-text-color);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 33.6px */
}
.text7{
    color: var(--tg-theme-text-color);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
}
.prizePrice{
    color: #000;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    border-radius: 16px;
    background: #F6CA46;
    padding: 3px 4px;
}