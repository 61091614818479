._{
    display: flex;
    width: calc(100% - 32px);
    height: 64px;
    padding: 0 16px;
    align-items: center;
    gap: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #4ac1f7;
    transition: all .3s ease;
    transform: translateY(64px);
    justify-content: space-between;
    cursor: pointer;
}

.prizeBlock1{
    display: flex;
    align-items: center;
    gap: 8px;
}
.prizeImgBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 100%;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    animation: glow 1.3s infinite alternate ease-in-out;
}
@keyframes glow {
    0% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    }
    50% {
        box-shadow: 0 0 25px rgba(255, 255, 255, 0.7);
    }
    100% {
        box-shadow: 0 0 15px rgba(255, 255, 255, 0.3);
    }
}
.prizeImg{
    border-radius: 100%;
    background: #FFF;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.prizeBlockTextBlock{

}
.prizeBlockText1{
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}
.prizeBlockText2{
    color: #FFF;
    font-size: 11px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    opacity: 0.64;
}
.prizeCountBlock{
    display: flex;
    gap: 6px;
}
.prizeCount{
    display: flex;
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.12);
    padding: 2px 8px;
    align-items: center;
    gap: 4px;
}
.prizeStr{
    margin: 3px 0 0 0;
    cursor: pointer;
}