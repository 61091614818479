.text1{
    color: var(--tg-theme-link-color);
    background: var(--tg-theme-bg-color);
    padding: 12px 20px;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    display: flex;
    margin: 12px 0;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}
.mainBlock{
    height: calc(100vh - 66px);
    overflow-x: auto;
}
.mainBlock2{
    height: calc(100vh - 66px);
    overflow-x: auto;
    background: var(--tg-theme-bg-color);
}
.footer{
    height: 66px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    cursor: pointer;
}
.footer2{
    height: 66px;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 100%;
    background: #E9E8E8;
    color: #BABABA;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    cursor: pointer;
}
.blockEl{
    background: var(--tg-theme-bg-color);
    padding: 16px 20px;
}
.text2{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-text-color);
}
.blockEl2{
    display: flex;
    align-items: flex-end;
}
.inputEl{
    color: var(--tg-theme-text-color);
    background: none;
    border: none;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 30px;
    height: min-content;
    min-width: 30px;
    margin: 0;
    padding: 0;
    font-family: monospace
}
.inputEl::placeholder{
    color: var(--tg-theme-text-color);
}
.text3{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--tg-theme-hint-color);
    margin-bottom: 4px;
}
.popup{
    position: fixed;
    width: calc(100% - 32px);
    height: calc(100% - 40px);
    background: var(--tg-theme-secondary-bg-color);
    top: 0;
    padding: 40px 16px 0 16px;
    overflow-x: auto;
}
.popup2{
    position: fixed;
    width: 100%;
    height: 100%;
    background: var(--tg-theme-secondary-bg-color);
    top: 0;
    overflow-x: auto;
}
.text1_1{
    color: var(--tg-theme-text-color);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 33.6px */
    margin-bottom: 40px;
}
.block_text{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    width: 100%;
}
.block{
    display: flex;
    padding: 9px 14px 9px 16px;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    justify-content: space-between;
}
.block_1{
    border-top: 1px solid var(--tg-theme-secondary-bg-color);
}
.blockModels{
    background: var(--tg-theme-bg-color);
    border-radius: 8px;
    margin-bottom: 16px;
}
.imageModelsBlock{
    height: 46px;
    width: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageModels{
    width: 100%;
}
.text4{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    color: var(--tg-theme-text-color);
}
.inputCountBlock{
    display: flex;
    gap: 5px;
    padding: 12px 24px 16px 24px;
    align-items: flex-end;
}
.inputCount{
    background: none;
    border: none;
    color:var(--tg-theme-text-color);
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 30px;
    min-width: 30px;
    height: min-content;
    margin: 0;
    padding: 0;
    font-family: monospace
}
.inputCount::placeholder{
    color:var(--tg-theme-text-color);
}
.inputCountNumber{
    color: var(--tg-theme-hint-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 4px;
}
.text5{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-text-color);
    text-align: center;
}
.text6{
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:var(--tg-theme-text-color);
    text-align: center;
}
.text7{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--tg-theme-hint-color);
    margin-left: 8px;
}
.blockProfit{
    margin: 16px;
    background: var(--tg-theme-secondary-bg-color);
    border-radius: 8px;
    padding-bottom: 1px;
}
.blockProfitHeader{
    display: flex;
    justify-content: space-evenly;
    padding: 12px 16px 15px 16px;
    border-bottom: 1px solid var(--tg-theme-bg-color);
    margin-bottom: 8px;
}
.blockProfitHeaderText{
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 146.667% */
    color: var(--tg-theme-hint-color);
    cursor: pointer;
    position: relative;
}
.block_count{
    color: var(--tg-theme-hint-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    white-space: nowrap;
}
.blockModels2{
    border: 1px solid var(--tg-theme-secondary-bg-color);
    margin: 0 16px 16px 16px;
}
.blockProfitInfo{
    display: flex;
}
.blockProfitInfoEnergy{
    width: 50%;
}
.blockProfitText1{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color:var(--tg-theme-text-color);
    width: 50%;
    gap: 8px;
    display: flex;
    height: fit-content;
    align-items: center;
}
.blockProfitText1_1{
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
    color:var(--tg-theme-text-color);
    gap: 8px;
    display: flex;
    height: fit-content;
    align-items: center;
}
.blockProfitText2{
    color:var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
}
.blockProfitText3{
    color: var(--tg-theme-hint-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */
}
.blockProfitHeaderText2{
    color: var(--tg-theme-link-color);
}
.blockProfitHeaderText2:before{
    position: absolute;
    content: '';
    background: var(--tg-theme-link-color);
    height: 3px;
    border-radius: 2px 2px 0 0;
    width: 100%;
    bottom: -15px;
}
.text8{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin: 100px 40px;
}
.blockProfitInfoBlock{
    background: var(--tg-theme-bg-color);
    margin: 8px;
    border-radius: 8px;
    display: flex;
    gap: 12px;
    flex-direction: column;
    padding: 12px;
}
.blockProfitSend{
    color: var(--tg-theme-link-color);
    display: flex;
    gap: 4px;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    height: 34px;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;
}
.popupSendFon{
    position: fixed;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    background: rgba(22,22,22,.4);
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 24px;
}
.popupSend{
    padding: 24px;
    background: var(--tg-theme-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 12px;
    width: 100%;
}
.popupSendText{
    display: flex;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    color: var(--tg-theme-text-color);
    opacity: .9;
}
.popupSendInput{
    display: flex;
    padding: 4px 4px 4px 16px;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--tg-theme-secondary-bg-color);
    line-height: 22px;
    margin-bottom: 12px;
    height: 36px;
}
.popupSendInputText{
    overflow: hidden;
    color: var(--tg-theme-text-color);
    font-feature-settings: 'calt' off;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    background: var(--tg-theme-bg-color);
    padding: 0;
    width: 100%;
    border: none;
}
.popupSendCopy{
    display: flex;
    width: 36px;
    min-width: 36px;
    height: 36px;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 4px;
    background: var(--tg-theme-link-color);
    cursor: pointer;
    position: relative;
}
.popupSendCopyText{
    border-radius: 6px;
    background: var(--tg-theme-link-color);
    display: flex;
    padding: 4px 12px 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    position: absolute;
    top: -40px;
}
.blockProfitSend2{
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
    text-transform: uppercase;
    background: var(--tg-theme-link-color);
    border-radius: 6px;
    width: calc(100% - 32px);
    cursor: pointer;
}
.blockProfitSendEdit{
    display: flex;
    justify-content: space-evenly;
}
.searchBlock{
    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 8px;
    background: var(--tg-theme-bg-color);
    padding: 16px 14px 16px 16px;
    cursor: pointer;
}
.searchBlock2{
    display: flex;
    align-items: center;
    gap: 12px;
}
.searchIcon{
    min-width: 32px;
}
.searchIcon2{
    min-width: 6px;
}
.searchText0{
    color: var(--tg-theme-text-color);;
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.48px;
    margin: 32px 10px;
}
.searchText1{
    color: var(--tg-theme-text-color);
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.34px;
}
.searchText2{
    color: var(--tg-theme-hint-color);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 138.462% */
    letter-spacing: -0.26px;
}
.emptyBlock{
    height: 1px;
    background: var(--tg-theme-hint-color);
    margin: 24px 0;
    opacity: .5;
}