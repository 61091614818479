._{
    width: calc(100%);
    height: calc(100%);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.block{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    position: fixed;
    width: calc(100% - 48px);
    height: calc(100% - 48px);
    overflow: hidden;
}
.close{
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 11;
}
.text1{
    color: #FFF;
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; /* 33.6px */
    letter-spacing: -0.48px;
}
.button{
    border-radius: 6px;
    background: #FFF;
    display: flex;
    height: 42px;
    padding: 0 10px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    cursor: pointer;
    width: calc(100% - 20px);
}
.text3{
    color: rgba(255, 255, 255, 0.80);
    text-align: center;
    font-feature-settings: 'calt' off;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: -0.26px;
    text-decoration-line: underline;
    cursor: pointer;
    width: fit-content;
}
.buttonBlock{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    align-items: center;
}
.prizeCount{
    display: flex;
    gap: 4px;
    align-items: center;
    border-radius: 20px;
    padding: 2px 8px;
}
.spinBlock{
    border-radius: 100%;
    width: 335px;
    height: 335px;
}
.text2{
    color: rgba(255, 255, 255, 0.64);
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.imageBlock{
    position: relative;
}
.image{
    height: 260px;
    z-index: 12;
}
.image2{
    position: absolute;
    height: 310px;
    width: 100%;
    left: 0;
    top: -30px;
    z-index: -1;
}
.buttonBlock2{
    display: flex;
    gap: 8px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.button2{
    border-radius: 6px;
    border: 1px solid #FFF;
    display: flex;
    height: 40px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    letter-spacing: -0.28px;
    text-transform: uppercase;
    cursor: pointer;
}
.button3{
    display: flex;
    height: 42px;
    padding: 0 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 157.143% */
    text-transform: uppercase;
    border-radius: 6px;
    background: #FFF;
    cursor: pointer;
}
.sale{
    height: 124px;
    position: absolute;
    left: 60px;
    top: 160px;
}
.buttonBlockLinks{
    display: flex;
    gap: 20px;
}