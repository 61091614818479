._{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.1);
    backdrop-filter: blur(3px);
    position: fixed;
    z-index: 5;
    opacity: 1;
    transition: opacity .3s ease;
}
._2{
    z-index: -1;
    opacity: 0;
}
.fan{
    height: 80px;
    width: 80px;
    animation-name: circle-to-square;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
@keyframes circle-to-square {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}